import React from 'react';
import { publicProfilePropTypes, userPropTypes } from 'helpers/proptypes';
import { Link } from 'react-router-dom';

import { PageHeader } from 'components';
import { EventLabels } from 'helpers/dictionaries';
import { getName } from 'helpers/utils';

import ProfileAlert from './ProfileAlert';

const PublicProfile = ({ profile, currentUser = {} }) => {
  const isCurrentUser = profile.user.pk === currentUser.pk;
  const currentIsManagerOrAdmin = currentUser.isManager || currentUser.isAdmin;
  return (
    <div className="row">
      <div className="col-lg-12">
        {isCurrentUser && <ProfileAlert display={profile.display} />}
        <div className="white-soft-box2 white-soft-box2--no-animate">
          <PageHeader.Basic
            title={profile.name}
            subtitle={profile.title}
            actions={
              isCurrentUser && (
                <a href={window.URLS['accounts:settings']()} className="btn btn-default">
                  Edit Profile
                </a>
              )
            }
          />
          <div className="profile-info-wrapper mt1">
            <div className="profile-avatar">
              <img
                src={
                  profile.avatar !== ''
                    ? profile.avatar
                    : 'https://www.gravatar.com/avatar/?d=mm&s=115'
                }
                height="145"
                width="119"
                alt={`${profile.name}'s Profile Picture`}
              />
            </div>
            {isCurrentUser && (
              <div className="visible-xs pull-right actions">
                <a href={window.URLS['accounts:settings']()} className="btn btn-default">
                  Edit Profile
                </a>
              </div>
            )}
            <div className="profile-info">
              <h3 className="section-header">Contact Information</h3>
              {profile.affiliation && (
                <p>
                  <strong>Affiliation:</strong>{' '}
                  {profile.affiliation.short_name || profile.affiliation.name}
                </p>
              )}
              <p>
                <strong>Manager: </strong>
                {profile.manager ? (
                  <Link
                    to={window.URLS['accounts:public_profile_detail'](profile.manager.profile.slug)}
                  >
                    <span> {getName(profile.manager.profile, 'profile')}</span>
                  </Link>
                ) : (
                  <span> No Manager</span>
                )}
              </p>
              {profile.office && (
                <p>
                  <strong>Office:</strong> {profile.office}
                </p>
              )}
              {profile.phone && (
                <p>
                  <strong>Phone:</strong> {profile.phone}
                </p>
              )}
            </div>
            {profile.show_certifications && (
              <div className="profile-info">
                <h3 className="section-header">Certification Details</h3>
                <p>{profile.certifications_detail}</p>
              </div>
            )}
            {(isCurrentUser || currentIsManagerOrAdmin) && (
              <div className="profile-info">
                <h3 className="section-header">
                  Resources{' '}
                  <small>
                    <span className="fa fa-lock icon-space-r icon-space-l" />
                    Only visible to you and your manager
                  </small>
                </h3>
                <p>
                  <strong>CEU Tracking: </strong>
                  {EventLabels.CEU_TRACKING_CHOICES[profile.ceu_tracking]}
                </p>
                <p>
                  <Link
                    to={window.URLS['accounts:pd_transcript'](profile.slug)}
                    className="btn btn-default"
                  >
                    PD Transcript
                  </Link>
                  {isCurrentUser && (
                    <a
                      href={window.URLS['accounts:registrations']()}
                      className="btn btn-default icon-space-l"
                    >
                      My Registrations
                    </a>
                  )}
                  {isCurrentUser && (
                    <a
                      href={window.URLS['pd:event_invitation_list']()}
                      className="btn btn-default icon-space-l"
                    >
                      Event Invitations
                    </a>
                  )}
                </p>
              </div>
            )}
          </div>
          <div className="row">
            {profile.about && (
              <div className="col-xs-12">
                <h2 className="section-header">About</h2>
                <div dangerouslySetInnerHTML={{ __html: profile.about }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PublicProfile.propTypes = {
  profile: publicProfilePropTypes,
  currentUser: userPropTypes,
};

export default PublicProfile;
