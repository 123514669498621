import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { profileDetailThunk } from 'store/actions/accounts/profiles';
import { publicProfilePropTypes, userPropTypes } from 'helpers/proptypes';
import { Alert, Loading } from 'components';
import PublicProfile from './PublicProfile';

class PublicProfileDetailPage extends React.Component {
  static propTypes = {
    profileDetailThunk: PropTypes.func.isRequired,
    requestCurrentUser: PropTypes.func.isRequired,
    profile: publicProfilePropTypes,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    user: userPropTypes,
  };

  componentDidMount() {
    this.props.profileDetailThunk(this.props.match.params.userSlug);
    this.props.requestCurrentUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.userSlug !== this.props.match.params.userSlug) {
      this.props.profileDetailThunk(this.props.match.params.userSlug);
    }
  }

  render() {
    const { profile, user, isLoading, hasErrored } = this.props;

    if (hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Error, could not retrieve profile information.
        </Alert>
      );
    }

    if (isLoading || !profile) {
      return <Loading />;
    }

    return <PublicProfile profile={profile} currentUser={user} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.profiles.keys.isLoading || state.accountsUser.isLoading,
    hasErrored: state.profiles.keys.hasErrored || state.accountsUser.hasErrored,
    profile: state.profiles.keys[props.match.params.userSlug],
    user: state.accountsUser[state.accountsUser.current],
  };
};

export default connect(
  mapStateToProps,
  { profileDetailThunk, requestCurrentUser }
)(PublicProfileDetailPage);
