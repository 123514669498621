import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'components';
import { ProfileDictionary } from 'helpers/dictionaries';

const { DISPLAY_CHOICES } = ProfileDictionary;

const ProfileAlert = ({ display }) => {
  if (display === DISPLAY_CHOICES.HIDDEN) {
    return (
      <Alert type="warning" icon="fa-exclamation-triangle">
        Profile is set to <strong>hidden</strong>. Only administrators and managers can view your
        profile.
      </Alert>
    );
  }

  if (display === DISPLAY_CHOICES.PRIVATE) {
    return (
      <Alert type="info" icon="fa-info-circle">
        Profile is set to <strong>private</strong>. Only registered users of this site can view your
        profile.
      </Alert>
    );
  }

  if (display === DISPLAY_CHOICES.PUBLIC) {
    return (
      <Alert type="success" icon="fa-check">
        Profile is set to <strong>public</strong>. Anyone can view your profile. This helps students
        learn more about their service providers.
      </Alert>
    );
  }
};

ProfileAlert.propTypes = {
  display: PropTypes.string.isRequired,
};

export default ProfileAlert;
